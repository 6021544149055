<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '20px' }]"><b>INFORMACIÓN ODT</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-row no-gutters>
          <v-col cols="12" md="6" class="pr-1">
            <texto2 :colores="colores" :titulo="'RAZÓN DE LLAMADA T24'" :descripcion="registro.gesodt.razon.descripcion"></texto2>
          </v-col>
          <v-col cols="12" md="6">
            <texto2 :colores="colores" :titulo="'DETALLE LLAMADA T24'" :descripcion="registro.gesodt.detalle.descripcion"></texto2>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
// import texto2 from '@/components/widgets/text2Component.vue'
// import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
export default {
  name: 'form_qr_cierreComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue')
  },
  mixins: [recFunciones],
  props: ['colores', 'registro'],
  data: () => ({

  }),
  methods: {

  }
}
</script>
